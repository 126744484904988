import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Structure, Span, Em, Strong, Link, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { MdMenu } from "react-icons/md";

import { useLoginState } from './userStateContext';

import localFavicon from '../resources/Icons/logo_32x32.ico';
import SOMALogo from '../resources/Logos/Logo.jpeg';

export default (() => {

	const { loginState, updateLoginState } = useLoginState(false);

	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"journal"} />
		<Helmet>
			<title>
				Instructons - For Authors
			</title>
			<meta name={"description"} content={"Web site created for NJSOMA"} />
			<link rel={"shortcut icon"} href={localFavicon} type={"image/x-icon"} />
		</Helmet>
		<Section>
			<Override slot="SectionContent" />
			<Box
				min-width="100px"
				min-height="100px"
				order="0"
				align-self="stretch"
				display="flex"
			>
				<Box display="flex" align-items="center" sm-flex-wrap="wrap">
					<Link
						opacity="0.6"
						text-decoration-line="initial"
						text-align="left"
						hover-text-decoration-line="underline"
						font="--base"
						color="--dark"
						white-space="nowrap"
						href="https://www.societyofmedicalanatomists.com/"
						margin="1px 0px 0px 10px"
					>
						<Image
							src={SOMALogo}
							display="block"
							max-height="150px"
							max-width="150px"
							srcSet=""
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
					</Link>
				</Box>
				<Box min-width="100px" min-height="100px" align-self="center">
					<Text margin="0px 0 24px 0" text-align="center" font="--headline2" md-font="--headline3">
						National Journal of Society of Medical Anatomists
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Input
						display="block"
						placeholder-color="LightGray"
						background="white"
						id="loginUser"
						className="LoginUser"
						defaultValue="User ID :"
						disabled
						opacity={0}/*{loginState ? 1 : 0}*/
					/>
					<Button opacity={0}/*{loginState ? 0 : 1}*/ id="loginRegBtn" className="LoginRegBtn" onClick={() => {
							//window.location.href='/loginPages/landingPage';	
						}}
					>
						Login
					</Button>
					<Button opacity={0}/*{loginState ? 1 : 0}*/ id="logoutRegBtn" className="LogoutRegBtn" onClick={() => {
							//window.location.href='/loginPages/landingPage';	
						}}
					>
						Logout
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="16px 0 16px 0" quarkly-title="Header" align-items="center" justify-content="center">
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				margin="0px 32px 0px 32px"
				width="100%"
			/>
			<Box min-width="100px" min-height="100px" />
			<Box>
				<Components.BurgerMenu md-display="flex" md-align-items="center" md-justify-content="flex-end">
					<Override slot="icon-open" md-position="fixed" md-top="18px" md-right="18px" />
					<Override
						slot="menu"
						md-top={0}
						md-width="100%"
						justify-content="center"
						lg-transition="transform 400ms ease 0s"
						md-position="fixed"
						display="flex"
						md-left={0}
						lg-transform="translateY(0px) translateX(0px)"
						md-height="100%"
						padding="0px 0 0px 0"
						align-items="center"
					>
						<Override
							slot="item"
							md-padding="16px 40px 16px 40px"
							display="inline-block"
							text-transform="uppercase"
							text-align="center"
							padding="8px 20px 8px 20px"
						/>
						<Override slot="item-404" lg-display="none" display="none" />
						<Override slot="item-index" lg-display="none" display="none" />
						<Override
							slot="link"
							md-hover-opacity="1"
							md-active-opacity="1"
							md-color="--dark"
							font="--base"
							text-transform="initial"
							md-font="16px/24px sans-serif"
							text-decoration-line="initial"
							color="--dark"
							transition="opacity .15s ease 0s"
							letter-spacing="0.5px"
							md-opacity=".5"
							md-transition="opacity .15s ease 0s"
							opacity=".5"
							hover-opacity="1"
						/>
						<Override
							slot="link-active"
							md-opacity="1"
							md-cursor="default"
							opacity="1"
							color="--primary"
							cursor="default"
						/>
					</Override>
					<Override slot="icon,icon-close" category="md" icon={MdMenu} />
					<Override
						slot="icon"
						category="md"
						icon={MdMenu}
						size="36px"
						md-right="0px"
						md-position="relative"
					/>
					<Override
						slot="menu-open"
						md-justify-content="center"
						md-top={0}
						md-bottom={0}
						md-display="flex"
						md-flex-direction="column"
						md-align-items="center"
					/>
				</Components.BurgerMenu>
			</Box>
		</Section>
		<Section>
			<Box min-width="100px" min-height="100px">
				<Link href="./#getting-started" color="#000000">
					Getting Started | 
				</Link>
				<Link href="./#tom" color="#000000">
					Types of Manuscripts | 
				</Link>
				<Link href="./#general-information" color="#000000">
					General Information | 
				</Link>
				<Link href="./#author-responsibility" color="#000000">
					Author Responsibility | 
				</Link>
				<Link href="./#ethics" color="#000000">
					Ethics | 
				</Link>
				<Link href="./#diee" color="#000000">
					Digital Image Editing Ethics | 
				</Link>
				<Link href="./#contribution-details" color="#000000">
					Contribution Details | 
				</Link>
				<Link href="./#coi-ci" color="#000000">
					Conflicts of Interest/Competing Interests | 
				</Link>
				<Link href="./#irbaic" color="#000000">
					Institutional Review Board Approval and Informed Consent | 
				</Link>
				<Link href="./#pop-rtp" color="#000000">
					Protection of Patients "Right to Privacy" | 
				</Link>
				<Link href="./#reporting-guidelines" color="#000000">
					Reporting Guidelines | 
				</Link>
				<Link href="./#editorial-process" color="#000000">
					The Editorial Process | 
				</Link>
				<Link href="./#ihs" color="#000000">
					In-house submissions | 
				</Link>
				<Link href="./#pom" color="#000000">
					Preparation of the Manuscript | 
				</Link>
				<Link href="./#laws" color="#000000">
					Language and Writing Style | 
				</Link>
				<Link href="./#oosiaa" color="#000000">
					Organization of Sections in an Article | 
				</Link>
				<Link href="./#cover-page" color="#000000">
					Cover Page | 
				</Link>
				<Link href="./#abstract" color="#000000">
					Abstract | 
				</Link>
				<Link href="./#keywords" color="#000000">
					Keywords | 
				</Link>
				<Link href="./#intro" color="#000000">
					Introduction | 
				</Link>
				<Link href="./#mam-o-sp" color="#000000">
					Materials and Methods or Subject Profile | 
				</Link>
				<Link href="./#ethics2" color="#000000">
					Ethics-2 | 
				</Link>
				<Link href="./#subjects-consent" color="#000000">
					Subject's consent | 
				</Link>
				<Link href="./#study-design" color="#000000">
					Study Design | 
				</Link>
				<Link href="./#stats" color="#000000">
					Statistics | 
				</Link>
				<Link href="./#results" color="#000000">
					Results | 
				</Link>
				<Link href="./#discussion" color="#000000">
					Discussion | 
				</Link>
				<Link href="./#ref" color="#000000">
					References | 
				</Link>
				<Link href="./#articles-in-journals" color="#000000">
					Articles in Journals | 
				</Link>
				<Link href="./#tables" color="#000000">
					Tables | 
				</Link>
				<Link href="./#graphs" color="#000000">
					Graphs | 
				</Link>
				<Link href="./#f-il" color="#000000">
					Figure/Image Legends | 
				</Link>
				<Link href="./#sotm" color="#000000">
					Submission of the Manuscript | 
				</Link>
				<Link href="./#original-article" color="#000000">
					Original Article | 
				</Link>
				<Link href="./#brief-report" color="#000000">
					Brief Report | 
				</Link>
				<Link href="./#review-article" color="#000000">
					Review Article | 
				</Link>
				<Link href="./#case-reports" color="#000000">
					Case Reports | 
				</Link>
				<Link href="./#sraf" color="#000000">
					Submitting revised article file | 
				</Link>
				<Link href="./#p-pf" color="#000000">
					Publication/Processing Fee | 
				</Link>
				<Link href="./#caoas" color="#000000">
					Copyright and Open Access Statement | 
				</Link>
				<Link href="./#cwfmoap" color="#000000">
					Compliance with Funder-Mandated Open Access Policies | 
				</Link>
				<Link href="./#privacy-statement" color="#000000">
					Privacy Statement | 
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Content-5">
			<Override slot="SectionContent" padding="0px 0 0px 0px" lg-padding="0px 0 0px 0px" />
			<Structure cells-number-total="4" cells-number-group="4">
				<Override slot="Content" grid-template-columns="9fr 3fr" md-grid-template-columns="repeat(6, 2fr)" sm-grid-template-columns="12fr">
					<Override
						slot="Cell 0th"
						grid-column="1 / auto"
						grid-row="auto / span 3"
						md-grid-column="1 / span 6"
						md-grid-row="span"
						sm-grid-column="auto"
						sm-grid-row="span"
					/>
					<Override slot="Cell 1st" md-grid-column="1 / span 2" sm-grid-column="auto" />
					<Override slot="Cell 2nd" md-grid-column="3 / span 2" sm-grid-column="auto" />
					<Override slot="Cell 3rd" md-grid-column="5 / span 2" sm-grid-column="auto" />
					<Override slot="cell-0">
						<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans" max-width="640px">
							Getting Started
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sans" color="#505257" max-width="640px">
							Authors need to submit their manuscripts through the online submission platform available at https://njsoma.societyofmedicalanatomists.com.{" "}
							<br />
							<br />
							Articles submitted as hard copies are not accepted. All first-time users need to register themselves. Registration is free. Once registered, authors can use their username and password to submit and keep track of their articles. In case of any problems, the author can contact the Technical Assistance at editorinchiefsoma@gmail.com.
						</Text>
					</Override>
					{"                                    "}
				</Override>
			</Structure>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Content-5" id="tom">
			<Override slot="SectionContent" padding="0px 0 0px 0px" lg-padding="0px 0 0px 0px" />
			<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans" max-width="640px">
				Types of Manuscripts
			</Text>
			<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sans" color="#505257" max-width="640px">
				NJSOMA publishes manuscripts in the following categories:{" "}
				<br />
				{"       "}1. Original Article
				<br />
				{"       "}2. Review Article
				<br />
				{"       "}3. Case Report{" "}
				<br />
				{"       "}4. Brief Report
				<br />
			</Text>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Content-6" id="general-information">
			<Override slot="SectionContent" padding="0px 0 0px 0px" lg-padding="0px 0 0px 0px" />
			<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans" max-width="840px">
				General Information
			</Text>
			<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sans" color="#505257" max-width="90%">
				NJSOMA publishes original works and findings that contribute to the advancements in the field of Anatomy.
				<br />
				{" "}
				<br />
				<Span
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<Span
						text-decoration-line="underline"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						id="author-responsibility"
					>
						Author Responsibility
					</Span>
				</Span>
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					{" "}:
				</Em>
				<br />
				{" "}The journal accepts only original work that has not been published elsewhere. All authors must confirm that neither the manuscript nor any part of it was written or published or is under consideration for publication elsewhere. Publication of the content as an abstract during the proceedings of meetings is not considered prior publication and can be submitted for publication. At the time of submission, authors should disclose details, if the study described in the manuscript had been previously presented in a meeting or published as an abstract. The details have to be mentioned in the Acknowledgments section. Any use of previously published material protected by copyright laws must be clearly acknowledged in the manuscript. Publishing of material on a website may be considered prior publication and should be mentioned at the time of submission. Authors should disclose details of related papers even those authored in a different language.{" "}
				<br />
				<br />
				Authorship credit should be based only on substantial contributions to any of the four components mentioned below:{" "}
				<br />
				1. Concept and design of the study, acquisition of data, or analysis and interpretation of data;{" "}
				<br />
				2. Drafting the article or revising it critically for important intellectual content;{" "}
				<br />
				3. Final approval of the version to be published; and{" "}
				<br />
				4. Aptitude to be accountable for all aspects of the work in ensuring that questions related to the accuracy or integrity of any part of the work are appropriately investigated and resolved.{" "}
				<br />
				<br />
				Each contributor should have participated sufficiently in the work to take public responsibility for the appropriate content of the manuscript. The order of naming the contributors should be based on the relative contribution of the individual included for authorship credit toward the study itself and the writing of the manuscript. Once the article has been submitted, the order cannot be changed without
written consent from all the contributors. The journal prescribes a maximum number of eight authors for all categories of manuscripts except for Case Reports that will have a maximum of four authors.{" "}
				<br />
				<br />
				The authors need to identify one author who will correspond with the Journal office in all matters related to the manuscript called the Corresponding Author. The content of the final manuscript including changes suggested by the Editor or reviewer of the manuscript is the responsibility of the corresponding author.
				<br />
				<br />
				<Span
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<Span
						text-decoration-line="underline"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						id="ethics"
					>
						Ethics :
					</Span>
				</Span>
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<Span
						text-decoration-line="underline"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						{" "}
					</Span>
				</Em>
				<br />
				Studies conducted must adhere to and be in accordance with The Code of Ethics of the World Medical Association (
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Declaration of Helsinki
				</Span>
				) for experiments involving humans. According to (
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Federal Research Misconduct Policy
				</Span>
				) scientific misconduct includes:{"  "}
				<br />
				{" "}- Data falsification : Fabrication, deceptive and selective reporting of findings, suppression of data and/or distortion of data{" "}
				<br />
				{" "}- Plagiarism : Use without permission the language, ideas, or thoughts of another and representation of them as one's own original work.
				<br />
				<br />
				Direct copying of sentences, whether from their previously published paper or from someone else's paper, is considered plagiarism. Authors need to check that they have not inadvertently "cut and paste" verbatim from published works.{" "}
				<br />
				According to the{" "}
				<Span
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					text-decoration-line="underline"
				>
					World Association of Medical Editors
				</Span>
				{" "}Plagiarism is the use of other's published and unpublished ideas or words (or other intellectual property) without attribution or permission, and presenting them as new and original rather than derived from an existing source. The intent and effect of plagiarism are to mislead the reader as to the contributions of the plagiarizer. This applies to whether the ideas or words are taken from abstracts, research grant applications, Institutional Review Board applications, or unpublished or published manuscripts in any publication format (print or electronic).{" "}
				<br />
				<br />
				Self-plagiarism refers to the practice of an author using portions of their previous writings on the same topic in another of their publications, without specifically citing it formally in quotes. This practice is widespread and sometimes unintentional. The journal requires authors to disclose information and cite references about reused content from previously published work of their own or of others.{" "}
				<br />
				<br />
				Incorrect authorship: Excluding authors, wrongly presenting the same material as original in more than one publication, the inclusion of authors who have not made a definite contribution to the work published; or submission of articles without the concurrence of all authors.{" "}
				<br />
				<br />
				Misappropriation of the ideas of others: an important aspect of scholarly activity is the exchange of ideas among colleagues. Scholars can acquire novel ideas from others during the process of reviewing grant applications and manuscripts. However, improper use of such information can constitute fraud. Wholesale appropriation of such material constitutes misconduct.{" "}
				<br />
				<br />
				Violation of generally accepted research practices: Improper manipulation of experiments to obtain biased results, deceptive statistical or analytical manipulations, or improper reporting of results.{" "}
				<br />
				<br />
				Material failure to comply with legislative and regulatory requirements affecting research: Violations of regulations and laws involving the use of funds, care of animals, human subjects, investigational drugs, recombinant products, new devices, or radioactive, biologic, or chemical materials.{" "}
				<br />
				<br />
				Any form of unethical behavior is strictly discouraged and will result in the submitting author and his group being banned from submitting material to the journal for a time frame depending on the severity of malpractice.{" "}
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="diee"
				>
					Digital Image Editing Ethics
				</Span>
				{" "}
				<br />
				No particular feature within an image may be introduced, moved, enhanced, obscured, or removed. Adjustments of brightness, contrast, or color balance are allowed if they are applied to the whole image and do not obscure or eliminate any information present in the original image. Adjustments such as changes to settings must be disclosed in the figure legend.
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="contribution-details"
				>
					Contribution Details
				</Span>
				{" "}:
				<br />
				The authors should provide a description of contributions made by each of them toward the manuscript. The description should be divided into the following categories, as deemed applicable: concept, design, the definition of intellectual content, literature search, clinical studies, experimental studies, data
acquisition, data analysis, statistical analysis, manuscript preparation, manuscript editing, and manuscript review. One or more authors should take responsibility for the integrity of the work as a whole from inception to published article and should be designated as "guarantor" or "guarantors".{" "}
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="coi-ci"
				>
					Conflicts of Interest/Competing Interests
				</Span>
				{" "}:
				<br />
				All authors must disclose any and all conflicts of interest they may have with the publication of the manuscript or any institution or product that is mentioned in the manuscript and/or is important to the outcome of the study presented. Authors should also disclose any conflict of interest with products that compete with those mentioned in their manuscript.{" "}
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="irbaaic"
				>
					Institutional Review Board Approval and Informed Consent
				</Span>
				{" "}:{" "}
				<br />
				All prospective and retrospective human studies must have appropriate institutional review board approval, and signed informed consent from all human participants is required. All animal studies must have an appropriate institutional review board or animal care committee approval.
				<br />
				<br />
				{" "}Compliance with these rules must be stated in the text, including a waiver of consent by the board, if applicable. Manuscripts that do not comply with these rules will not be accepted for publication.{" "}
				<br />
				<br />
				Patient consent and or Ethical approval statements along with protocol number and date must be included in all research articles.{" "}
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="pop-rtp"
				>
					Protection of Patients "Right to Privacy"
				</Span>
				{" "}:
				<br />
				For all research involving human subjects, informed consent to participate in the study should be obtained from participants (or their parent or guardian in the case of children under 16) and a statement to this effect should appear in the manuscript.{" "}
				<br />
				<br />
				Identifying information, including patients "names, initials, or hospital numbers", should not be published in written descriptions, CT scans, photographs, sonograms, and pedigrees unless the information is essential for scientific purposes and the patient (or parent or guardian) gives written informed consent for publication. Informed consent for this purpose requires that a patient who is identifiable be shown the manuscript to be published.{" "}
				<br />
				<br />
				Informed consent should be obtained if there is any doubt that anonymity can be maintained. For example, masking the eye region in photographs of patients is inadequate protection of anonymity. If identifying characteristics are altered to protect anonymity, such as in genetic pedigrees, authors should provide assurance that alterations do not distort scientific meaning and editors should so note.{" "}
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="reporting-guidelines"
				>
					Reporting Guidelines{" "}
				</Span>
				:
				<br />
				Reports of randomized clinical trials should present information on all major study elements, including the protocol, assignment of interventions (methods of randomization, concealment of allocation to treatment groups), and the method of masking (blinding), based on the CONSORT Statement. Reporting Guidelines for Specific manuscript types are given below. A statement of compliance with the appropriate guideline must be included on the title page of the manuscript.{" "}
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						id="editorial-process"
					>
						The Editorial Process
					</Span>
				</Strong>
				<br />
				NJSOMA has a highly rigorous double-blind peer-review process that makes sure that manuscripts are scientifically accurate, relevant, novel, and meaningful.{" "}
				<br />
				<br />
				Manuscripts are subjected to an unbiased double-blinded peer-review process. Decisions on accepting manuscripts for publication are solely based on the peer-review process, and the Editor's discretion and the decision are final. The manuscript is sent for peer review to at least two external reviewers, and if required, it is sent to a third reviewer to act as a tie-breaker. A completed review by two reviewers recommending the acceptance of the manuscript for publication is mandatory.{" "}
				<br />
				<br />
				Manuscripts submitted for publication in the NJSOMA are sent for blind review. The journal follows a double-blind review process, wherein the reviewers and authors are unaware of each other's identity. The comments and suggestions (acceptance/rejection/amendments to the manuscript) received from reviewers are conveyed to the author. The author is requested to provide a point-by-point response to reviewer's comments and submit a revised manuscript version. This process is repeated until reviewers and editors are satisfied with the manuscript.
				<br />
				<br />
				Manuscripts accepted for publication are copy-edited for grammar, punctuation, print style, and format. Galley proofs are sent to the author. The author is expected to return the corrected proofs within three days. It may not be possible to incorporate corrections received after that period. The process of submitting the manuscript to the final decision and sending and receiving proofs is completed online. To achieve faster and greater dissemination of knowledge and information, the journal publishes articles online immediately on final acceptance.{" "}
				<br />
				<br />
				Manuscripts that do not conform to the Author Guidelines or exceed the Maximums set for Articles may be returned to the author without review. Authors can resubmit after complying with the requirements.{" "}
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="ihs"
				>
					In-house submissions
				</Span>
				{" "}:
				<br />
				{" "}In-house submissions that contain the work of any editorial board member are not allowed to be reviewed by that editorial board member, and an independent editor makes all decisions regarding this manuscript. In addition, these manuscripts are reviewed by two external reviewers. This is also disclosed in the published manuscript under the section of Conflict of Interest.{" "}
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="pom"
				>
					Preparation of the Manuscript
				</Span>
				{" "}:
				<br />
				The manuscript should be double-spaced, with a 2.5 cm margin, 12-point Times New Roman font and justified. The pages of the manuscript should be numbered on the bottom right corner.
				<br />
				<br />
				{" "}
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="laws"
				>
					Language and Writing Style
				</Span>
				{" "}:
				<br />
				The manuscript should be written in American English. The author should write the full term for each abbreviation at its first use in the title, abstract, keywords and text separately unless the abbreviation is a standard unit of measure. The use of acronyms and abbreviations must be kept to a minimum. When used, they are defined at first mention, followed by the acronym or abbreviation in parentheses. Manuscripts will be altered to meet the style guidelines of the NJSOMA. The authors are requested to check the manuscript for spelling, grammar, and punctuation errors before submission. Headings and titles must be in sentence case and not in Capitals.{" "}
				<br />
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						id="oosiaa"
					>
						Organization of Sections in an Article
					</Span>
					{" "}
				</Strong>
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="cover-page"
				>
					Cover Page
				</Span>
				{" "}:
				<br />
				The cover page includes :
				<br />
				{"  "}(a) complete manuscript title;{" "}
				<br />
				{"  "}(b) List all authors full names, highest academic degrees, professional titles, affiliations, and locations of affiliations;{" "}
				<br />
				{"  "}(c) name and address of the corresponding author, including, telephone number, and e-mail address and{" "}
				<br />
				{"  "}(d) sources of support that require acknowledgment, and a short title of no more than 45 characters (including spaces) for use as a running title.{" "}
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="abstract"
				>
					Abstract
				</Span>
				{" "}:
				<br />
				An abstract is required for all categories of manuscripts. The abstract should not exceed 500 words. The content should not have any subheadings.{" "}
				<br />
				<br />
				For an Original Research article, the abstract should be divided into four sections: Objective, Material and Methods, Results, and Conclusions. It should not exceed 500 words.{" "}
				<br />
				<br />
				No abstract should contain any figures, tables, or references, trade names, or manufacturer's names.{" "}
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="keywords"
				>
					Keywords
				</Span>
				{" "}:
				<br />
				Provide up to five keywords at the end of the abstract for all types of articles except for Letters to the Editor and Replies.{" "}
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="intro"
				>
					Introduction
				</Span>
				{" "}:
				<br />
				All articles need an Introduction that describes the objective of the investigation in not more than 500 words. The author should clearly state the specific goal or purpose of the article, and indicate why it is worthy of attention. In the Introduction, describe the hypothesis to be tested, the dilemma to be resolved, or the deficiency to be remedied.
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="mam-o-sp"
				>
					Materials and Methods or Subject Profile
				</Span>
				{" "}:
				<br />
				The research plan, the materials (or subjects), and the methods used  as well as the details of the data obtained and how it was analyzed 
should be described.{"  "}
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="ethics2"
				>
					Ethics
				</Span>
				{" "}: When reporting studies on human subjects, indicate whether the procedures followed were in accordance with the ethical standards of the responsible committee on human experimentation (institutional or regional) and with the Helsinki Declaration of 1975, as revised in 2000 (available at{" "}
				<Span
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<Span
						text-decoration-line="underline"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						https://www.wma.net/policies-post/wma-declaration-of-helsinki-ethical-principles-for-medical-research-involving-human-subjects/
					</Span>
				</Span>
				). For prospective studies involving human participants, authors are expected to mention about approval of (regional/national/institutional 
				or independent Ethics Committee or Review Board, obtaining informed consent from adult research participants and obtaining assent for 
				children aged over 7 years participating in the trial. The age beyond which assent would be required could vary as per regional and/or 
				national guidelines. Ensure confidentiality of subjects by desisting from mentioning participant's names, initials or hospital numbers, 
				especially in illustrative material. When reporting experiments on animals, indicate whether the institution's or a national research 
				council's guide for or any national law on the care and use of laboratory animals were followed.{" "}
				<br />
				<br />
				Evidence for approval by a local Ethics Committee (for both human as well as animal studies) must be supplied by the authors. 
				Animal experimental procedures should be as humane as possible and should be clearly stated. The ethical standards of experiments 
				must be in accordance with the guidelines provided by the (CPCSEA and World Medical Association Declaration of Helsinki on Ethical 
				Principles for Medical Research Involving Humans for studies involving experimental animals and human beings, respectively). 
				The journal will not consider any manuscript which is ethically unacceptable. The editorial decision will be final in this regards 
				and no further communication will be done. A statement on ethics committee permission and ethical practices must be included in all 
				original research manuscripts under the Materials and Methods section.{" "}
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="subjects-consent"
				>
					Subject's consent
				</Span>
				{" "}: Patient anonymity must be maintained in all submissions. If there is any possibility that the patient can be identified in a figure, written consent must be obtained from the Subject or his or her parent/guardian by the author, and a line stating that this has been received included in the article.{" "}
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="study-design"
				>
					Study Design
				</Span>
				{" "}:
				<br />
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Selection and Description of Participants
				</Em>
				{" "}: Describe your selection of the observational or experimental participants (patients or laboratory animals, including controls) clearly, including eligibility and exclusion criteria and a description of the source population. Technical information: Identify the methods, apparatus (give the manufacturer's name and address in parentheses), and procedures in sufficient detail to allow other workers to reproduce the results. Give references to established methods, including statistical methods (see below); provide references and brief descriptions for methods that have been published but are not well known; describe new or substantially modified methods, give reasons for using them, and evaluate their limitations. Identify precisely all drugs and chemicals used, including generic name(s), dose(s), and route(s) of administration.{" "}
				<br />
				<br />
				Reports of randomized clinical trials should present information on all major study elements, including the protocol number and date, assignment of interventions (methods of randomization, concealment of allocation to treatment groups), and the method of masking (blinding), based on the CONSORT Statement (
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					http://www.consort-statement.org
				</Span>
				).{" "}
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="stats"
				>
					Statistics
				</Span>
				{" "}:
				<br />
				Whenever possible quantify findings and present them with appropriate indicators of measurement error or uncertainty (such as confidence intervals). The authors should report losses to observation (such as dropouts from a clinical trial). When data are summarized in the Result section, specify the statistical methods used to analyze them. Avoid non-technical uses of technical terms in statistics, such as "random" (which implies a randomizing device), "normal", "significant", "correlations", and "sample". Define statistical terms, abbreviations, and most symbols. Specify the computer software used. For all P values include the exact value and not less than 0.05 or 0.001. Mean differences in continuous variables, proportions in categorical variables and relative risks including odds ratios and hazard ratios should be accompanied by their confidence intervals.{" "}
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="results"
				>
					Results
				</Span>
				:
				<br />
				All results should be in a clear, logical sequence and should adhere to the specific objective of the paper. Data presented in tables should not be duplicated in the text. Important trends and points observed in the study will need to be described. When data are summarized in the Results section, give numeric results not only as derivatives (for example, percentages) but also as the absolute numbers from which the derivatives were calculated, and specify the statistical methods used to analyze them. Restrict tables and figures to those needed to explain the argument of the paper and to assess its support. Use graphs as an alternative to tables with many entries; do not duplicate data in graphs and tables. Where scientifically appropriate, analyses of the data by variables such as age and sex should be included.{" "}
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="discussion"
				>
					Discussion
				</Span>
				{" "}:
				<br />
				The article submitted should explain clearly the outcome of the research considering the goals and results obtained. Any limitations on the materials or subjects and methods must be included. How the results differ from those obtained by previous investigators needs to be presented with adequate comparisons and explanations. Include key findings (primary outcome measures, secondary outcome measures, results as they relate to a prior hypothesis); Strengths and limitations of the study (study question, study design, data collection, analysis and interpretation); Interpretation and implications in the context of the totality of evidence should be described. Include what this study adds to the available evidence, effects on patient care and health policy, etc. A line on controversies raised by this study; and future research directions (for this particular research collaboration, underlying mechanisms, clinical research) may be included.{" "}
				<br />
				<br />
				Do not repeat the data or other material mentioned in the Introduction or the Results section. In particular, contributors should avoid making statements on economic benefits and costs unless their manuscript includes economic data and analyses. Avoid claiming priority and alluding to work that has not been completed.{" "}
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="ref"
				>
					References
				</Span>
				{" "}:
				<br />
				References are to be numbered sequentially in the order in which they appear in the manuscript. Reference numbers are typed as superscripts, enclosed by square brackets.{" "}
				<br />
				<br />
				References follow the ICMJE guidelines. The author's surname is followed by the author's initials in capitals without spaces or full stops. All references show page numbers in the format (121-26). Refer to the List of Journals Indexed in Index Medicus for abbreviations of journal names.
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Sample references are given below
				</Span>
				{" "}:
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="articles-in-journals"
				>
					Articles in Journals
				</Span>
				{" "}:
				<br />
				1.Olson MC, Posniak HV, Fisher SG, Flisak ME, Salomon CG, Flanigan RC, et al. Directed and random biopsies of the prostate: indications based on combined results of transrectal sonography and prostate-specific antigen density determinations. Am J Roentgenol 1994;163:1407–11.{" "}
				<br />
				<br />
				List the first six contributors followed by et al in all references.{" "}
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="tables"
				>
					Tables
				</Span>
				{" "}:
				<br />
				Tables must be significant and provide a good summary of the study. Tables should be self-explanatory and not duplicate the data given in the text or figures. Each table must have a descriptive title, and each column a heading. Tables must contain a minimum of four lines and two columns of data and not exceed 10 columns and 25 rows. Tables are numbered in the order in which they are cited in the text. Abbreviations used in the tables are defined below each table. All arithmetic calculations (percentages, totals, differences) must be double-checked for accuracy, and data must agree with the data given in the text.{" "}
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="graphs"
				>
					Graphs
				</Span>
				{" "}:
				<br />
				Graphs need to be exported as JPEG or TIF images and submitted as figures. Graphs and line drawings need to be a minimum of 1000 dpi. Graphs should include clearly labeled error bars described in the figure legend. The authors must state whether a number that follows the ± sign is a standard error (SEM) or a standard deviation (SD). The number of independent data points (N) represented in a graph must be indicated in the legend. Numerical axes on graphs should go to 0, except for log axes.
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="f-il"
				>
					Figure/Image Legends
				</Span>
				{" "}:
				<br />
				All figure parts relating to one image should have the same figure number. The style for figure legends is given here: Figure 1: Age and gender of the patient followed by presenting symptoms and subsequent diagnosis. Imaging modality used, organ/section imaged, view, and abnormality seen in the image. Add arrows pointing to the abnormality seen in the image.
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="sotm"
				>
					Submission of the Manuscript
				</Span>
				:
				<br />
				<br />
				Online Manuscript Submission
				<br />
				All manuscripts must be submitted online at https://www.njsoma.societyofmedicalanatomists.com You will see step-by-step instructions when you are submitting your manuscript. You will need to submit the following documents as separate files.
				<br />
				<br />
				1. Cover Page: The cover page includes (a) complete manuscript title; (b) List all author's full names, highest academic degrees, professional titles, affiliations, and locations of affiliations; (c) name and{" "}
				<br />
				address of the corresponding author, including, telephone number, and e-mail address and (d) sources of support that require acknowledgment, and a short title of no more than 45 characters (including spaces) for use as a running title.
				<br />
				<br />
				2. Manuscript: This document should include the title, abstract, keywords, manuscript body, and references. This must be in the document format. Acknowledgment Section should be included at the end of the main manuscript. Acknowledgment should not contain the name of the city or the institution. The text lines need to be numbered.
				<br />
				<br />
				3. Figures: Figures must be submitted in .tiff or jpeg format. Color images should be of good quality. Each image should be less than 1 MB in size. The size of the image can be reduced by decreasing the actual height and width of the images (1240 x 800 pixels or 5-6 inches). Original dissected specimen should be submitted with and without labels. 
				<br />
				<br />
				Figure legends are uploaded in a document format.{" "}
				<br />
				{" "}- Graphs: Do not embed them in the main manuscript. They should be uploaded separately at the time of uploading the figures in the “jpeg” or “tiff” format.{" "}
				<br />
				{" "}- Tables: Tables should be included in the main manuscript file after the references.{" "}
				<br />
				{" "}- Copyright and disclosure forms are available for download from the author's panel and should be uploaded as PDF files.{" "}
				<br />
				{" "}- The author's passport size photo is required and can be uploaded at the time of uploading figures etc.
				<br />
				{" "}- Instruction on supplemental digital multimedia/video content: In addition to the video content, authors must provide a still image from each video file. Supply TIFF or JPEG files. These are also uploaded at the time of figure upload.{" "}
				<br />
				<br />
				Specific Details for Each Manuscript Type
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="original-article"
				>
					Original Article
				</Span>
				:
				<br />
				An original article is a research paper based on unique findings and techniques and provides new information on the topic. The data section should be based on randomized clinical trials and contain information on all relevant study elements.
				<br />
				<br />
				Appropriate statistical data is necessary for findings to be conclusive. The Methods section should contain the criteria for the selection of human participants and laboratory animals involved in the trials and provide evidence of approval from relevant ethics organizations. When human subjects are involved, the methods followed and the extent to which they were in accordance with ethical standards and Human Rights guidelines need to be indicated.
				<br />
				<br />
				The article should include the following sections:{" "}
				<br />
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Abstract
				</Em>
				{" "}: Please provide{" "}
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Objectives
				</Em>
				,
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					{" "}Methods and Materials
				</Em>
				,{" "}
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Results
				</Em>
				, and{" "}
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Conclusion
				</Em>
				{" "}in brief in the Abstract and do not exceed 500 words.
				<br />
				<br />
				Please provide the following in detail in the main article: Introduction, Methods, Results, Discussion, and Conclusion (3000 words). Original articles can have references up to a maximum of 35, figures including subparts up to a maximum of 10, and Tables up to a maximum of 4.
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="brief-report"
				>
					Brief Report
				</Span>
				{" "}:
				<br />
				Short reports of preliminary studies may be submitted in the category of Brief Reports with maximum of 1500 words. These manuscripts must be structured like Original Articles (see the immediately preceding section).
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="review-article"
				>
					Review Article
				</Span>
				{" "}:
				<br />
				A review article is a balanced, in-depth scholarly study of the latest trends or present status of a specific timely topic, but is not an original article. No new data or personal experiences are presented. It is an analysis of the advances in the field based on a literature review of the topic. A review article includes a concise abstract limited to 500 words. The main article with a detailed description of each disease process with appropriate images and a valid conclusion can be a maximum of 3000 words. The author can include up to a maximum of 90 references, 30 figures including subparts, and up to 4 tables.{" "}
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="case-reports"
				>
					Case Reports
				</Span>
				{" "}:
				<br />
				Case reports should be new, unique, and clinically significant. The cases must have a diagnostic impact or describe a therapeutic challenge and must provide a learning opportunity for clinicians. A cluster of cases can also be submitted under the category of case reports. A cluster will depict one disease process with different presentations.{" "}
				<br />
				<br />
				Case reports must include an Abstract (up to a maximum of 500 words), Introduction, Case report, Discussions, and Conclusion. (up to a maximum of 1500 words), references up to a maximum of 10, figures up to a maximum of 6, and a maximum of one table. Case reports can have up to four authors.
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="sraf"
				>
					Submitting revised article file
				</Span>
				:
				<br />
				The revised version of the manuscript should be submitted online in a manner similar to that used for the submission of the manuscript for the first time. When submitting the revised manuscript, contributors are requested to include, the "referees" remarks along with point to point clarification in the COMMENTS FILE and REBUTTAL FILE appropriately and uploaded in the appropriate place. In addition, for the ease of the editorial team, the authors are expected to mark the changes as underlined or colored text in the article.
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="p-pf"
				>
					Publication/Processing Fee
				</Span>
				{" "}:
				<br />
				Article Processing Charge : Nil {" "}
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="caoas"
				>
					Copyright and Open Access Statement
				</Span>
				{" "}:
				<br />
				<br />
					Authors Retain Copyright :	 
				<br />
				<br />
					All of the content published in the NJSOMA is protected under the
					International copyright law, defined by Creative Commons and International
					Council of Medical Journal Editors (ICMJE). The author of an article retains
					the academic copyright of the content and can self-archive the article. The
					journal retains the commercial rights of the published content and publisher
					executes the commercial rights on behalf of the journal. The journal also grants
					to all readers and users a free, irrevocable, global, perpetual right of access
					to, and a license to copy, use, distribute and display the content publicly and
					to make and distribute derivative works in any digital medium for any
					reasonable and non-commercial purpose, subject to proper attribution of
					authorship and ownership of the copyrights under the Creative Commons
					Attribution-Noncommercial-Share Alike 4.0 International Public License.


				<br />
				<br />
					Open Access Publication and Creative Commons Licensing :
				<br />
				<br />
					NJSOMA is an
					open-access journal, and manuscripts published are distributed under the terms
					of the Creative Commons Attribution-Non Commercial-Share Alike 4.0 License
					(CC-BY-NC-SA 4.0), which allows others to remix, transform, and build upon the
					work non-commercially, as long as appropriate credit is given and the new
					creations are licensed under the identical terms.
				<br />
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="cwfmoap"
				>
					Compliance with Funder-Mandated Open Access Policies
				</Span>
				{" "}:
				<br />
				An author whose work is funded by an organization that mandates the use of the Creative Commons Attribution-Non-Commercial-Share Alike 4.0 License is able to meet that requirement through the available open-access license for approved funders.{" "}
				<br />
				<br />
				<Span
					text-decoration-line="underline"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					id="privacy-statement"
				>
					Privacy Statement
				</Span>
				{" "}:
				<br />
				The names and email addresses entered in this journal site will be used exclusively for the stated purposes of this journal and will not be made available for any other purpose or to any other party. For any Questions or Difficulties please contact us at editorinchiefsoma@gmail.com.
			</Text>
		</Section>
		<Section
			box-sizing="border-box"
			quarkly-title="Footer"
			justify-content="center"
			margin="0 0 0 0"
			padding="16px 0 16px 0"
		>
			<Override
				slot="SectionContent"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Box
				width="100%"
				grid-template-columns="repeat(2, 1fr)"
				sm-grid-gap="16px"
				display="grid"
				grid-gap="32px"
			>
				<Box display="flex" align-items="center" sm-flex-wrap="wrap">
					<Image
						width="28px"
						height="28px"
						src={SOMALogo}
						srcSet=""
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Link
						opacity="0.6"
						text-decoration-line="initial"
						text-align="left"
						hover-text-decoration-line="underline"
						font="--base"
						color="--dark"
						white-space="nowrap"
						href="https://www.societyofmedicalanatomists.com/"
						margin="1px 0px 0px 10px"
					>
						SOMA
					</Link>
				</Box>
				<SocialMedia facebook="https://www.facebook.com/NJSOMA/" twitter="https://twitter.com/njsoma" youtube="https://www.youtube.com/channel/UCK5bXs2L0bbSMQ82BQ3hIkw" justify-content="flex-end">
					<Override slot="link" background="none" border-radius="50%" />
					<Override slot="icon" color="--dark" />
				</SocialMedia>
			</Box>
		</Section>
	</Theme>;
});